import React from "react"

function useForm(recaptchaRef, validate, submitForm) {
  const [state, setState] = React.useState({})
  const [errors, setErrors] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  function handleBlur() {
    const validationErrors = validate(state, recaptchaRef)
    setErrors(validationErrors)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const validationErrors = validate(state, recaptchaRef)
    const validSubmit = Object.keys(validationErrors).length === 0
    setErrors(validationErrors)
    if (validSubmit) {
      submitForm(e.target)
    }
  }
  return {
    handleSubmit,
    handleChange,
    handleBlur,
    state,
    errors,
  }
}
export default useForm
