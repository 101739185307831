import React, { Fragment } from "react"
import Form from "../component/Form"

export default function Contact() {
  return (
    <Fragment>
      <section className="pb-20 relative block bg-gray-900">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px", transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-900 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div className="flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold text-white">
                Let's Build Something
              </h2>
              <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                You are few steps away from making your dream project happen.
                Get started with requesting a quote, you will be surprised how
                little it costs.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap mt-12 justify-center">
            <div className="w-full lg:w-3/12 px-4 text-center">
              <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i className="fas fa-medal text-xl"></i>
              </div>
              <h6 className="text-xl mt-5 font-semibold text-white">
                Excellent Service
              </h6>
              <p className="mt-2 mb-4 text-gray-500">
              Our #1 priority is to make our customers happy. We achieve that my providing top notch customer service.
              </p>
            </div>
            <div className="w-full lg:w-3/12 px-4 text-center">
              <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i className="fas fa-poll text-xl"></i>
              </div>
              <h5 className="text-xl mt-5 font-semibold text-white">
                Grow Your Business
              </h5>
              <p className="mt-2 mb-4 text-gray-500">
              Research shows that building engaging sites with user accessibility in mind tripled conversions.
              </p>
            </div>
            <div className="w-full lg:w-3/12 px-4 text-center">
              <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i className="fab fa-searchengin text-xl"></i>
              </div>
              <h5 className="text-xl mt-5 font-semibold text-white">
                SEO Services
              </h5>
              <p className="mt-2 mb-4 text-gray-500">
              Increase your site traffic and conversion by ranking well in all search engines.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Form />
    </Fragment>
  )
}
