export default function validateForm(values, recaptchaRef) {
  let errors = {}
  if (!values.name) {
    errors.name = "* Name Required"
  }

  if (!values.message) {
    errors.message = "* Message Required"
  }

  // Email Errors
  if (!values.email) {
    errors.email = "* Email Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "* Invalid email address"
  }

  if (!recaptchaRef.current.getValue()) {
    errors.recaptcha = "* reCaptcha Required"
  }
  return errors
}
