import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      services: file(relativePath: { eq: "services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1051) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="pb-20 pt-10 sm:pt-0 bg-gray-300 -mt-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="pt-6 w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                  <i className="fas fa-award"></i>
                </div>
                <h6 className="text-xl font-semibold">Awarded Agency</h6>
                <p className="mt-2 mb-4 text-gray-600">
                  Our team is made up of extremely talented designers and
                  developers. They have produced award winning projects.
                </p>
              </div>
            </div>
          </div>

          <div className="pt-6 w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                  <i className="fas fa-dollar-sign"></i>
                </div>
                <h6 className="text-xl font-semibold">Competitive Pricing</h6>
                <p className="mt-2 mb-4 text-gray-600">
                  We provide quality services at nominal prices. You don't need
                  to break bank for outstanding website. We got you covered.
                </p>
              </div>
            </div>
          </div>

          <div className="pt-6 w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                  <i className="fas fa-truck"></i>
                </div>
                <h6 className="text-xl font-semibold">On Time Delivery</h6>
                <p className="mt-2 mb-4 text-gray-600">
                  We deliver all our projects on time. If we can't keep our
                  promise, you don't need to pay us a penny.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center mt-20">
          <div className="w-full md:w-5/12 px-4 pb-8 md:pb-0 mr-auto ml-auto">
            <div className="flex flex-wrap items-center">
              <div className="w-full text-center">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl "></i>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center">
              <div className="w-full">
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Collaborate With Us
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Let us be your A team for providing all services needed for
                  expanding your reach to more customers. We can help you build
                  a brand not just business.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  Become our partner by helping us grow. It's easy to build
                  trust when we are helping each other. This allows us to forge
                  a long-lasting relationship then a mere business transaction.
                </p>
                {/* <a
                  href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                  className="font-bold text-gray-800 mt-8"
                >
                  Check Tailwind Starter Kit!
                </a> */}
              </div>
            </div>
          </div>

          <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
              <Img
                fluid={data.services.childImageSharp.fluid}
                className="w-full align-middle rounded-t-lg"
              />

              <blockquote className="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  className="absolute left-0 w-full block"
                  style={{
                    height: "95px",
                    top: "-94px",
                  }}
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    className="text-pink-600 fill-current"
                  ></polygon>
                </svg>
                <h4 className="text-xl font-bold text-white">
                  Top Notch Services
                </h4>
                <p className="text-md font-light mt-2 text-white">
                  We are one stop shop for all your web needs. We provide
                  services like logo design, website design, seo, digital
                  marketing and web hosting.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
