import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1267, fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div
      className="relative pt-16 pb-32 flex content-center items-center justify-center"
      style={{
        minHeight: "75vh",
      }}
    >
      <div className="absolute top-0 w-full h-full ">
        <BackgroundImage
          Tag="section"
          className=" w-full h-full"
          fluid={data.imageOne.childImageSharp.fluid}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-75 bg-black"
          ></span>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12 mt-24 md:pt-40 lg:pt-0">
                  <h1 className="text-white font-semibold text-5xl">
                    We help small business thrive.
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
                    As a small business ourselves we understand all intricacies
                    of running one. For us it’s your mission/vision we want to
                    share it with the world. We truly believe it’s the same
                    reason you started your business in first place. We ensure
                    you that we will put in same effort into our projects like
                    you do everyday for your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </BackgroundImage>
      </div>
    </div>
  )
}
